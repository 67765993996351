import React from "react";
import Backdrop from "@mui/material/Backdrop";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  logo: {
    width: 120,
  },
}));

export default function AppLoader() {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={true} onClick={() => null}>
      <img
        alt="logo"
        src="/assets/images/logo_oae.png"
        className={classes.logo}
      />
    </Backdrop>
  );
}
